import "./hangmenGame.scss"

const Hangman = () => {
  return (
    <div>
      <h1>Hangman Game</h1>
    </div>
  )
}

export default Hangman
